<template>
  <div
    :class="routeNameCheck($router?.currentRoute?.value?.name).isTestCasesPage
      ? 'row justify-between items-center test-case-search' : 'row justify-between items-center validations-search'"
  >
    <div :class="isBomValidationRoute ? 'filter_wrappers col-6' : 'filter_wrappers_test-cases'">
      <!-- Breadcrumbs -->
      <mi-breadcrumbs
        v-show="breadcrumbs.length && currentRouteMatch.isBomValidationResultsPage"
        :items="breadcrumbs"
      ></mi-breadcrumbs>
      <div
        v-show="isTestCaseResultsRoute"
        class="flex items-center cursor-pointer"
        role="button"
        tabindex="0"
        @click="goBack"
      >
        <img
          src="@/assets/images/arrow-left.svg"
          alt="go back test cases page"
          class="back-icon"
        />
        <span class="back-text text-uppercase">
          back
        </span>
      </div>
      <!-- Validations Filters bar -->
      <validations-filters-bar v-if="routeNameCheck($router?.currentRoute?.value?.name).isBomValidationsPage">
      </validations-filters-bar>
      <test-case-filters-bar
        v-if="routeNameCheck($router?.currentRoute?.value?.name).isTestCasesPage"
      >
      </test-case-filters-bar>
      <!-- Search field -->
      <mi-text-field
        v-show="displaySearch"
        :model-value="searchModelValue"
        bg-color="white"
        debounce="300"
        placeholder="Search"
        clearable
        hide-bottom-space
        outlined
        class="search-field"
        @update:model-value="updateInputModelValue"
      >
        <template #prepend>
          <mi-icon name="search"></mi-icon>
        </template>
      </mi-text-field>
    </div>

    <!-- Action buttons -->
    <div class="col-auto col-auto-btn">
      <!-- Export excel (bom validation results) -->
      <mi-btn
        v-if="currentRouteMatch.isBomValidationResultsPage"
        :disable="!bomCurrentElements.hasAnyErrorTypeResults"
        :loading="isValidationExporting"
        color="accent"
        class="btn-export-data"
        icon-right-size="16px"
        @click="exportValidationResultsExcel(exportTypes.bom)"
      >
        Export Data
      </mi-btn>

      <!-- New validation -->
      <mi-btn
        v-if="isBomValidationRoute"
        color="accent"
        icon-right="plus-circle"
        icon-right-size="14px"
        @click="SET_IS_NEW_VALIDATION_DIALOG_SHOWN(true)"
      >
        New Validation
      </mi-btn>
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { exportFile } from 'quasar'
  import { exportBomValidation } from '@/api'
  import { TEST_CASES_ROUTE } from '@/router/routeNames'
  import { VALIDATION_ROUTES_AVAILABLE, routeNameCheck } from '@/components/validations/utils'
  import {
    SET_IS_NEW_VALIDATION_DIALOG_SHOWN,
    SET_VALIDATIONS_SEARCH_STRING,
    SET_TEST_CASE_FILTERS
  } from '@/store/modules/validations/mutationTypes'
  import ValidationsFiltersBar from '@/components/validations/filters/ValidationsFiltersBar.vue'
  import TestCaseFiltersBar from '@/components/validations/filters/TestCaseFiltersBar.vue'

  const { mapMutations, mapState } = createNamespacedHelpers('validations')

  export default {
    name: 'ValidationsHeader',
    components: { TestCaseFiltersBar, ValidationsFiltersBar },
    emits: ['click:upload-excel'],
    data: () => ({
      isTestCaseValidationExporting: false,
      isBomValidationExporting: false,
      isValidationExporting: false,
      exportTypes: {
        bom: 'BOM'
      }
    }),
    computed: {
      ...mapState(['validationsSearchString', 'bomCurrentElements', 'testCaseFilters']),
      displaySearch() {
        const routeName = this.$route.name
        const routeCheck = routeNameCheck(routeName)

        const { isUnusableChoicesPage, isTestCasesResultsPage, isTestCasesPage } = routeCheck

        return !this.breadcrumbs.length
          && !isUnusableChoicesPage && !isTestCasesPage && !isTestCasesResultsPage
      },
      searchModelValue() {
        return routeNameCheck(this.$route.name).isTestCasesPage
          ? this.testCaseFilters.search : this.validationsSearchString
      },
      breadcrumbs() {
        return this.$route.meta?.breadcrumbs?.map(({ label = '', to } = {}) => {
          let updatedLabel = label

          updatedLabel = updatedLabel.replace(/:(?!:)\S+/, match => {
            const routeParamName = match?.slice(1)

            return this.$route.params[routeParamName] || ''
          })

          return { label: updatedLabel, to }
        }) || []
      },

      currentRouteMatch() {
        const routeMatches = this.$route.matched?.map(({ name }) => name)

        const matchedRoutes = Object.fromEntries(
          Object.entries(VALIDATION_ROUTES_AVAILABLE).map(([key, routeName]) => [key, routeMatches.includes(routeName)])
        )

        return matchedRoutes
      },
      isBomValidationRoute() {
        return this.currentRouteMatch.isBomValidationResultsPage || this.currentRouteMatch.isBomValidationsRoute
      },
      isTestCaseResultsRoute() {
        return this.currentRouteMatch.isTestCaseValidationResultsPage || this.currentRouteMatch.isTestCaseComparisonPage
      }
    },
    mounted() {
      // this wasn't working check for future iterations
      this.$watch(
        () => this.$route.name,
        (to, from) => {
          // Check if the URL changes from bomValidations to testCases Or vice-versa
          if ((from === 'bomValidations' && to === 'testCases')
            || (from === 'testCases' && to === 'bomValidations')
            || (from === 'testCaseValidations' && to === 'bomValidations')) {
            this.SET_VALIDATIONS_SEARCH_STRING()
          }
        }
      )
    },
    methods: {
      ...mapMutations({
        SET_IS_NEW_VALIDATION_DIALOG_SHOWN,
        SET_VALIDATIONS_SEARCH_STRING,
        SET_TEST_CASE_FILTERS
      }),
      routeNameCheck,

      async exportValidationResultsExcel(prefix) {
        const { id = '' } = this.$route.params || {}

        this.isValidationExporting = true

        try {
          const excelFile = await exportBomValidation(id)
          const filename = `${ prefix }_validation_${ id }.xlsx`

          exportFile(filename, new Blob([excelFile]))
        }
        finally {
          this.isValidationExporting = false
        }
      },
      updateInputModelValue(value) {
        const isTestCasePage = routeNameCheck(this.$router?.currentRoute?.value?.name).isTestCasesPage

        if (isTestCasePage) {
          this.SET_TEST_CASE_FILTERS({ type: 'search', val: value || '' })
        }
        else {
          this.SET_VALIDATIONS_SEARCH_STRING(value)
        }
      },
      goBack() {
        this.$router.push(TEST_CASES_ROUTE)
      }
    }
  }
</script>

<style lang="scss">
  .filter_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .search-field {
    width: 100%;
  }

  .mi-divider {
    border: 1px solid $mi-anthracite-50;
    height: 27px;
  }

  .validations-search {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .test-case-search {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .filters_wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 50%;
  }

  .col-auto-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .btn-export-data {
      margin-right: 8px;
    }
  }

  .filter_wrappers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .filter_wrappers_test-cases {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20%;
  }

  .back-text {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding-left: 8px;
  }

  .back-icon {
    width: 18px;
    height: 14px;
  }

  .compare-results-btn {
    margin-right: 16px;
  }
</style>
